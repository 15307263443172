.token_keyword {
    color: blueviolet;
}


.token_literal_value {
    color: rgb(1, 162, 82);
}

.token_logical_keyword {
    color: rgb(1, 160, 228)
}

.token_known_variable {
    color: rgb(0, 83, 255);
}